.note-wrapper {
  margin-top: 20px;

  .note-row {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 5px;

    .date {
      font-weight: bold;
      width: 200px;
    }

    .creator {
      font-weight: bold;
      color: $darker_gray;
      width: 150px;
    }

    .note {}
  }
}

.note-input {
  display: flex;
  flex-direction: row;
  margin-top: 20px;

  input {
    width: 100%;
  }

  button {
    margin-left: 10px;
    width: 105px;
  }
}