.modal-backdrop {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;

  .modal {
    z-index: 9999;
    position: fixed;
    top: 5%;
    left: 5%;
    width: calc(90% - 30px);
    margin: 0 auto;
    background-color: $white;
    border: 1px solid $border_color;
    border-radius: 10px;
    height: 90%;
    max-height: 90%;
    overflow: auto;

    .close-btn {
      position: absolute;
      color: $black;
      top: 15px;
      right: 0px;
      width: 40px;
      height: 40px;
      cursor: pointer;
    }

    &.small {
      left: calc(50% - 200px);
      width: 400px;
      min-height: 30px;
      height: auto;

      .label-row label {
        min-width: 100px;
      }
    }

    &.medium {
      left: calc(50% - 300px);
      width: 600px;
      min-height: 30px;
      height: auto;

      .label-row label {
        min-width: 100px;
      }
    }

    .modal-container {
      display: flex;
      flex-direction: column;
      height: 100%;

      &.modal-center {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
      }

      .modal-edit-employee {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 100%;

        .label-row {
          flex-direction: row;
          align-items: flex-start;

          label {
            width: 100px;
            min-width: 100px;
          }

          input:disabled {
            height: 15px;
          }
        }
      }

      .modal-header {
        display: flex;
        flex-direction: column;
        height: 50px;
        font-size: 22px;
        color: $black;
        font-weight: 700;
        padding: 15px 30px;
        justify-content: center;
        padding-bottom: 10px;

        .modal-tab-bar {
          display: flex;
          flex-direction: row;
          gap: 5px;

          .modal-tab {
            margin-top: 10px;
            margin-bottom: -10px;
            height: 15px;
            padding: 5px 20px;
            font-size: 12px;
            font-weight: normal;
            cursor: pointer;
            background-color: $color_subtitle;
            border: 1px solid $border_color;
            border-bottom: 1px solid $table_hover;
            color: $darker_gray;
            z-index: 99;
            border-radius: 10px 10px 0px 0px;

            &.active {
              color: $black;
              font-weight: bold;
              background-color: $table_hover;
              border-bottom: 1px solid $table_hover;
            }
          }
        }
      }

      .modal-body {
        padding: 30px;
        height: 100%;
        overflow-y: scroll;
        background-color: $table_hover;
        border-top: 1px solid $border_color;
        border-bottom: 1px solid $border_color;
      }

      .modal-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 15px 30px;

        button {
          min-width: 150px;
          justify-content: center;
          box-shadow: none;
        }

        .btn-bar-left {
          display: flex;
        }

        .btn-bar-right {
          display: flex;

          button {
            margin-left: 15px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $mobile_size) {
  .modal-backdrop {
    .modal {
      top: 10%;
      left: 10%;
      width: calc(80% - 40px);
      padding: 20px;

      .close-btn {
        right: 13%;
        top: 5%;
      }

      &.modal-wizard {
        input {
          width: calc(100% - 20px);
        }
      }

      &.full-mobile {
        position: absolute;
        z-index: 999999;
        border: none;
        top: 0px;
        left: 0px;
        border-radius: 0px;
        width: calc(100% - 40px);
        height: calc(100% - 40px);
        max-height: none;

        .close-btn {
          right: 5px;
          top: 5px;
        }
      }
    }
  }
}
