.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 260px;
  background-color: $white;
  border-right: 1px solid $border_color;
  overflow-y: scroll;
  height: 100vh;

  .sidebar-logo {
    height: 70px;

    a {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid $border_color;

      .sidebar-logo-img {
        height: 34px;
        padding-top: 5px;
        padding-bottom: 5px;
      }

      .sidebar-logo-text {
        font-weight: bold;
      }
    }
  }

  .sidebar-menu,
  .sidebar-bottom {
    display: flex;
    flex-direction: column;
    padding: 15px;

    .sidebar-btn {
      padding: 8px 10px;
      border-radius: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $black;

      -webkit-transition: color 0.3s ease-out;
      -moz-transition: color 0.3s ease-out;
      -o-transition: color 0.3s ease-out;
      transition: color 0.3s ease-out;

      -webkit-transition: background-color 0.3s ease-out;
      -moz-transition: background-color 0.3s ease-out;
      -o-transition: background-color 0.3s ease-out;
      transition: background-color 0.3s ease-out;

      .material-icons {
        margin-right: 10px;
      }

      svg {
        margin-right: 10px;

        path {
          fill: $black;
        }
      }

      &:hover {
        color: $green;
      }

      &.active:not(.not-active) {
        background-color: $black;
        color: $white;

        &:hover {
          color: $green;
        }
      }
    }
  }

  .sidebar-menu {
    height: 100%;
  }

  .sidebar-bottom {
    border-top: 1px solid $border_color;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .user {
      margin-left: 10px;
    }

    .sidebar-btn-icon {
      height: 20px;

      svg {
        path {
          fill: $dark_gray;
          color: $dark_gray;
        }
      }
    }
  }
}