// Header
$black: #202224;
$darker_gray: #595c5e;
$dark_gray: #8d99ae;
$table_text: #949596;
$color_subtitle: #b9baba;
$border_color: #e0e0e0;
$table_hover: #f7f7f7;
$bg_gray: #f3f2f8;
$white: #fff;
$light_gray: #0000003d;
$light_green: #52e195;
$green: #25aa63;
$orange: #f3b94d;
$red: #f3a0a0;
$blue: #0070b8;
$light_blue: #ddedfa;
$light_blue_hover: #b4daf9;
$yellow: #fdf6bd;

// Sizes
$mobile_size: 425px;
$tablet_size: 768px;
$desktop_size: 1024px;

// Fonts
$font_title: Roboto;
$font_body: Roboto;

// Sansation
@font-face {
  font-family: "Sansation";
  font-style: normal;
  font-weight: 400;
  src: url(fonts/sansation/Sansation_Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Sansation";
  font-style: normal;
  font-weight: 700;
  src: url(fonts/sansation/Sansation_Bold.ttf) format("truetype");
}

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400&display=swap");
